import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import { TwitterTimelineEmbed, TwitterVideoEmbed } from "react-twitter-embed";

import moment from "moment";
import "moment/locale/fr";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";

import { Modal, Skeleton } from "antd";

import Step1 from "./onboarding/Step1.js";
import Step2 from "./onboarding/Step2.js";
import Step3 from "./onboarding/Step3.js";
import { Select, Button, Drawer } from "antd";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";
import MenuFeed from "../components/MenuFeed.js";
import Plans from "../components/Plans.js";
import TikTokEmbed from "../components/TikTokEmbed.js";

import mixpanel from "mixpanel-browser";

import FeedLoading from "../components/FeedLoading.js";

// STYLES
import "./Feed.css";
import FooterLogin from "../components/footerLogin.js";

function Feed() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { isFreeTrialOver, isFreeTrial, setSelectedSources, selectedSources } =
    context;
  moment.locale("fr"); // Utiliser la locale française pour l'affichage des dates
  const [dataFromSearch, setDataFromSearch] = useState(null);
  // const [selectedSources, setSelectedSources] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  // const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bgColorClass, setBgColorClass] = useState("");
  const [selectedVignetteIndex, setSelectedVignetteIndex] = useState(null);
  const [isPremiumTeam, setIsPremiumTeam] = useState(false);
  const [nombreResults, setNombreResults] = useState(0);
  const [allPosts, setAllPosts] = useState([]);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [isClockView, setIsClockView] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [sentimentData, setSentimentData] = useState(null);
  const [mentionFilter, setMentionFilter] = useState("all");
  const [displayedPostsCount, setDisplayedPostsCount] = useState(20);
  const [selectedAd, setSelectedAd] = useState(null);
  const [adPosition, setAdPosition] = useState(Math.floor(Math.random() * 6));
  const [previousDisplayedPostsCount, setPreviousDisplayedPostsCount] =
    useState(20);
  const [showAd, setShowAd] = useState(true);
  const [isTrialOverModalVisible, setIsTrialOverModalVisible] = useState(false);

  const { Option } = Select;

  const keyword = context.keyword;

  let currentPlan = "Free";

  // Vérifiez d'abord si l'utilisateur est sur un plan d'équipe et attribuez ce plan
  if (
    context.teamDetails &&
    context.teamDetails.plan &&
    context.teamDetails.plan.length > 0
  ) {
    currentPlan = context.teamDetails.plan[0].productName;
  }

  // Ensuite, vérifiez si la période d'essai n'est pas encore terminée et ajustez le plan en conséquence
  if (!isFreeTrialOver && currentPlan === "Free") {
    currentPlan = "Pro"; // Attribuez le plan "Pro" pendant la période d'essai pour les utilisateurs Free
  }

  const showDrawer = (index) => {
    setSelectedVignetteIndex(index);
    setOpen(true);
  };

  const onClose = () => {
    setSelectedVignetteIndex(null);
    setOpen(false);
  };

  const showModal = (post) => {
    mixpanel.track("Share_Mention", {
      user: context.userInfos.email,
    });

    setSelectedPost(post);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const updateSelectedPostEvaluate = (evaluation) => {
    setSelectedPost((prevPost) => ({
      ...prevPost,
      evaluate: evaluation,
    }));
  };

  const adContents = [
    {
      id: 2,
      title: t("Did_you_know"),
      text: t("you_can_setup_your_notification_for_new_mentions"),
      image: "Al_on_rocket.svg",
      buttonText: t("get_notified"),
      plans: ["Pro", "Free"],
    },
    {
      id: 3,
      title: t("Did_you_know"),
      text: t("you_can_search_up_to_10_different_keywords"),
      image: "Relax.svg",
      buttonText: t("get_more_keywords"),
      plans: ["Pro"],
    },
    {
      id: 4,
      title: t("Did_you_know"),
      text: t("we_can_also_track_mentions_of_your_brand"),
      image: "delivery.svg",
      buttonText: t("access_file_search"),
      plans: ["Pro"],
    },
    {
      id: 5,
      title: t("Did_you_know"),
      text: t("you_can_see_instantly_if_a_mention_of_your_brand"),
      image: "Work_x5F_out.svg",
      buttonText: t("get_sentiment_analysis"),
      plans: ["Free"],
    },
    {
      id: 6,
      title: t("Did_you_know"),
      text: t("you_can_create_several_teams"),
      image: "Desk_x5F_work.svg",
      buttonText: t("Invite_teammates"),
      plans: ["Pro"],
    },
    {
      id: 7,
      title: t("Did_you_know"),
      text: t("you_can_invite_team_members_to_join_your_team"),
      image: "Piggyback.svg",
      buttonText: t("Invite_teammates"),
      plans: ["Free"],
    },
    {
      id: 8,
      title: t("Did_you_know"),
      text: t("we_can_also_track_mention_of_your_brand"),
      image: "Searching.svg",
      buttonText: t("Access_more_sources"),
      plans: ["Free"],
    },
  ];

  useEffect(() => {
    const detectedLanguages = [
      ...new Set(allPosts.map((post) => post.language)),
    ];

    // Sélectionner toutes les langues par défaut
    setSelectedLanguages(detectedLanguages);
  }, [selectedSources]);

  useEffect(() => {
    // Filtrer les annonces selon le plan de l'utilisateur
    const adsForUserPlan = adContents.filter((ad) =>
      ad.plans.includes(currentPlan)
    );

    // Sélectionner une annonce aléatoire parmi celles filtrées
    if (adsForUserPlan.length > 0) {
      const randomIndex = Math.floor(Math.random() * adsForUserPlan.length);
      setSelectedAd(adsForUserPlan[randomIndex]);
    }
  }, [currentPlan, keyword]);

  useEffect(() => {
    setLoading(true);
    // Vérifiez si la période d'essai est terminée et si l'utilisateur n'est pas premium
    if (isFreeTrial() && !isPremiumTeam) {
      setIsTrialOverModalVisible(true); // Affichez la modal si les conditions sont remplies
      setLoading(false);
    } else {
      setIsTrialOverModalVisible(false); // Sinon, assurez-vous que la modal est cachée
      setLoading(false);
    }
  }, [isFreeTrial, isPremiumTeam]);

  const GoodReview = async (selectedPost) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/search/updateGoodReview`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({
            postId: selectedPost.id,
            sourceKey: selectedPost.sourceKey,
          }),
        }
      );

      const data = await response.json();

      // Vérifiez la réponse de l'API
      if (data.success) {
        mixpanel.track("GoodReview");
        updateSelectedPostEvaluate("good");
      } else {
        // Gérer les erreurs
        console.error("Error updating post:", data.message);
      }
    } catch (error) {
      console.error("Error when calling API :", error);
    }
  };

  const BadReview = async (selectedPost) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/search/updateBadReview`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({
            postId: selectedPost.id,
            sourceKey: selectedPost.sourceKey,
          }),
        }
      );

      const data = await response.json();

      // Vérifiez la réponse de l'API
      if (data.success) {
        mixpanel.track("BadReview");
        updateSelectedPostEvaluate("bad");
      } else {
        // Gérer les erreurs
        console.error("Error updating post:", data.message);
      }
    } catch (error) {
      console.error("Error when calling API :", error);
    }
  };

  const groupByDate = (allPosts) => {
    return allPosts.reduce((acc, post) => {
      const date = new Date(post.date).toDateString(); // Convertissez la date en chaîne de caractères formatée (par ex. "Mon Feb 01 2023")
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(post);
      return acc;
    }, {});
  };

  const sourceOptions = Object.keys(
    dataFromSearch?.searches?.[0]?.results || {}
  ).map((key) => (
    <Option key={key} value={key}>
      {key}
    </Option>
  ));

  // const cleanHTML = DOMPurify.sanitize(highlightKeyword(post.content, keyword));

  function highlightKeyword(content, keyword) {
    const regex = new RegExp(`(${keyword})`, "gi");
    return content.replace(regex, '<span class="highlightedKeyword">$1</span>');
  }

  // useEffect(() => {
  //   if (!dataFromSearch) return;

  //   let tempAllPosts = [];

  //   // Assurez-vous que la recherche contient le bon mot-clé
  //   const currentSearch = dataFromSearch.searches.find(
  //     (search) => search.keyword === context.keyword
  //   );

  //   if (currentSearch) {
  //     if (Array.isArray(selectedSources)) {
  //       selectedSources.forEach((source) => {
  //         if (
  //           currentSearch.results[source] &&
  //           Array.isArray(currentSearch.results[source].posts)
  //         ) {
  //           currentSearch.results[source].posts.forEach((post) => {
  //             tempAllPosts.push({
  //               ...post,
  //               sourceKey: source,
  //               searchDate: currentSearch.createdAt,
  //             });
  //           });
  //         }
  //       });
  //     }
  //   }

  //   setAllPosts(tempAllPosts);
  //   console.log("tempAllPosts", tempAllPosts);
  //   setNombreResults(tempAllPosts.length);
  // }, [dataFromSearch, selectedSources, keyword]);

  const exportModalAsImage = () => {
    const modalElement = document.querySelector(".ant-modal-content");
    const footerElement = modalElement.querySelector(".custom-footer");

    // Cachez temporairement le footer
    footerElement.style.display = "none";

    html2canvas(modalElement).then((canvas) => {
      // Réaffichez le footer
      footerElement.style.display = "flex";

      // Convertissez le canvas en image et téléchargez-le
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "exportBuska.png";
      link.click();
    });
  };

  const renderIconForSource = (sourceKey, faviconUrl) => {
    if (sourceKey === "news") {
      return (
        <img
          src="/background/newsGoogle.png"
          className="googleFeed"
          alt="Google News"
        />
      );
    } else if (sourceKey === "indiehacker") {
      return (
        <img
          src="/social/indiehacker.png"
          className="googleFeed"
          alt="Indie Hacker"
        />
      );
    } else if (sourceKey === "twitter") {
      return <i className="fa-brands fa-x-twitter fa-3x"></i>;
    } else {
      return <i className={`${sourceKey} fa-brands fa-${sourceKey} fa-3x`}></i>;
    }
  };

  useEffect(() => {
    // Si la modal est visible, trouvez l'élément .ant-modal-content et modifiez sa classe
    if (isModalVisible) {
      const modalContentElement = document.querySelector(".ant-modal-content");
      if (modalContentElement) {
        modalContentElement.className = `ant-modal-content ${bgColorClass}`;
      }
    }
  }, [bgColorClass, isModalVisible]);

  const handlePostClick = async (post, index) => {
    setSelectedPostId(post.id);
    // Vérifiez si le post n'a pas encore été lu
    if (!post.read) {
      // Appelez votre API pour mettre à jour le champ read
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/search/updatePostRead`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${context.token}`,
            },
            body: JSON.stringify({
              postId: post.id,
              sourceKey: post.sourceKey,
            }),
          }
        );

        const data = await response.json();

        // Vérifiez la réponse de l'API
        if (data.success) {
          // console.log("Mise à jour réussie");

          const updatedPosts = [...allPosts];
          updatedPosts[index].read = true;
          setAllPosts(updatedPosts);
        } else {
          // Gérer les erreurs
          console.error(
            "Erreur lors de la mise à jour du post :",
            data.message
          );
        }
      } catch (error) {
        console.error("Error when calling API :", error);
      }
    }

    // Continuer avec le reste de la logique existante
    showDrawer(index);
    setSelectedPost(post);
  };

  function TwitterEmbed({ embedCode }) {
    useEffect(() => {
      // Fonction pour charger le script de widgets Twitter
      const loadTwitterScript = () => {
        if (!window.twttr) {
          const script = document.createElement("script");
          script.src = "https://platform.twitter.com/widgets.js";
          script.async = true;
          script.charset = "utf-8";
          document.body.appendChild(script);
        } else {
          window.twttr.widgets.load();
        }
      };

      loadTwitterScript();
    }, [embedCode]); // Exécuté à chaque fois que embedCode change

    return (
      <div
        className="twitter-embed"
        dangerouslySetInnerHTML={{ __html: embedCode }}
      />
    );
  }

  const loadPinterestScript = () => {
    if (window.PinUtils) {
      window.PinUtils.build();
    } else if (!window.pintrk) {
      window.pintrk = true;
      const script = document.createElement("script");
      script.src = "https://assets.pinterest.com/js/pinit.js";
      script.setAttribute("async", "async");
      script.setAttribute("defer", "defer");
      script.onload = () => {
        if (window.PinUtils) {
          window.PinUtils.build();
        }
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Appel de la fonction pour charger le script lors du premier montage du composant
    loadPinterestScript();
  }, []);

  useEffect(() => {
    // Appel de la fonction quand le post sélectionné change et qu'il est de type 'pinterest'
    if (selectedPost && selectedPost.sourceKey === "pinterest") {
      loadPinterestScript();
    }
  }, [selectedPost]);

  const loadInstagramScript = () => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    } else {
      const script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.instgrm.Embeds.process();
      };
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Appel de la fonction pour charger le script lors du premier montage du composant
    loadInstagramScript();
  }, []);

  const InstagramPost = ({ url }) => {
    useEffect(() => {
      const scriptId = "instagram-embed-script";

      // Vérifie si le script existe déjà
      let script = document.getElementById(scriptId);
      if (!script) {
        // Crée le script seulement s'il n'existe pas déjà
        script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://www.instagram.com/embed.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
          if (window.instgrm) {
            window.instgrm.Embeds.process();
          }
        };
      } else if (window.instgrm) {
        // Si le script existe déjà, exécutez simplement la fonction de traitement
        window.instgrm.Embeds.process();
      }

      // Fonction de nettoyage
      return () => {
        // Supprime le script par son ID s'il n'est plus nécessaire
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
          existingScript.remove();
        }
      };
    }, [url]); // Se relance lorsque l'URL change

    return (
      <blockquote
        className="instagram-media"
        data-instgrm-captioned
        data-instgrm-permalink={url}
        data-instgrm-version="14"
        style={{
          background: "#FFF",
          border: "0",
          borderRadius: "3px",
          boxShadow: "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
          margin: "1px",
          maxWidth: "540px",
          minWidth: "100%",
          padding: "0",
          width: "99.375%",
          width: "-webkit-calc(100% - 2px)",
          width: "calc(100% - 2px)",
        }}
      ></blockquote>
    );
  };

  useEffect(() => {
    // Assurez-vous que le DOM a été mis à jour avec le nouveau contenu
    // avant d'appeler le processus Instagram
    const processInstagram = () => {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      }
    };

    // Vous devez attendre deux cycles d'événements pour vous assurer que le DOM est mis à jour.
    setTimeout(processInstagram, 0);

    // Assurez-vous que le script est chargé.
    const scriptId = "instagram-embed-script";
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, [selectedPost]); // Dépendance au post pour que cela se réexécute si le post change

  function getInstagramEmbedHtml(url) {
    // La structure de base d'une intégration Instagram (à remplacer par la structure réelle nécessaire)
    // console.log("url", url);
    return `<blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14"></blockquote>`;
  }

  useEffect(() => {
    // Assurez-vous que le script TikTok est chargé
    const tiktokScriptId = "tiktok-embed-script";
    let tiktokScript = document.getElementById(tiktokScriptId);
    if (!tiktokScript) {
      tiktokScript = document.createElement("script");
      tiktokScript.id = tiktokScriptId;
      tiktokScript.src = "https://www.tiktok.com/embed.js";
      document.body.appendChild(tiktokScript);
    }
  }, []);

  function getEmbeddedContent(post) {
    // Déterminez le contenu à intégrer en fonction de la source du post
    if (post) {
      switch (post.sourceKey) {
        case "linkedin":
          if (post.link) {
            const iframeSrc = post.link.replace(
              "feed/update",
              "embed/feed/update"
            );
            return (
              <div className="alignEmbed rounded">
                <iframe
                  src={iframeSrc}
                  height="833"
                  width="504"
                  frameBorder="0"
                  allowFullScreen=""
                ></iframe>
              </div>
            );
          }
          break;
        case "twitter":
          if (post.link) {
            const tweetIdMatch = post.link.match(/status\/(\d+)/);
            const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;

            const twitterLink = post.link.replace("x.com", "twitter.com");

            if (tweetId) {
              const twitterEmbedCode = `<blockquote class="twitter-tweet">
              <p>${t("loading")}</p>
              <a href="${twitterLink}"></a>
            </blockquote>`;
              return <TwitterEmbed embedCode={twitterEmbedCode} />;
            }
          }
          break;
        case "youtube":
          if (post && post.sourceKey === "youtube" && post.link) {
            // Extrait l'ID de la vidéo YouTube à partir de l'URL
            const videoIdMatch = post.link.match(
              /(?:youtu\.be\/|youtube\.com\/(?:shorts\/|watch\?v=|embed\/|v\/))([\w-]{10,12})/
            );
            const videoId = videoIdMatch ? videoIdMatch[1] : null;

            if (videoId) {
              const youtubeEmbedUrl = `https://www.youtube.com/embed/${videoId}`;
              return (
                <div className="alignEmbed rounded">
                  <iframe
                    width="504"
                    height="283" // Hauteur standard pour une intégration 16:9 avec une largeur de 504px
                    src={youtubeEmbedUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="rounded"
                  ></iframe>
                </div>
              );
            }
          }
          break;
        case "pinterest":
          if (post.link) {
            const pinterestEmbedCode = `<a data-pin-do="embedPin" href="${post.link}"></a>`;
            return (
              <div
                className="alignEmbed rounded interest-embed"
                dangerouslySetInnerHTML={{ __html: pinterestEmbedCode }}
              />
            );
          }
          break;
        case "instagram":
          if (post.link) {
            return (
              <div
                className="rounded alignEmbed instagram-post"
                dangerouslySetInnerHTML={{
                  __html: getInstagramEmbedHtml(post.link),
                }}
              />
            );
          }
          break;
        case "tiktok":
          if (post.link) {
            return <TikTokEmbed link={post.link} />;
          }
          break;
        case "facebook":
          if (post.link) {
            // Supposons que post.link est l'URL directe de la vidéo Facebook que vous souhaitez intégrer.
            const encodedUrl = encodeURIComponent(post.link);
            const iframeSrc = `https://www.facebook.com/plugins/post.php?href=${encodedUrl}&show_text=true`;

            return (
              <div className="alignEmbed rounded">
                <iframe
                  src={iframeSrc}
                  width="560"
                  height="600"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
            );
          }
          break;
        case "news":
          if (post.link) {
            return (
              <div className="alignEmbed rounded">
                <p
                  className="preserve-newlines"
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(
                      post ? post.name : t("No_post_selected"),
                      keyword
                    ),
                  }}
                ></p>
              </div>
            );
          }
          break;
      }
    }

    // S'il n'y a pas de contenu à intégrer ou si la source n'est pas gérée, renvoyez le contenu de repli
    return (
      <p
        className="preserve-newlines"
        dangerouslySetInnerHTML={{
          __html: highlightKeyword(
            post ? post.content : t("No_post_selected"),
            keyword
          ),
        }}
      ></p>
    );
  }

  async function getSentimentAnalysis(post) {
    // Vérifier si l'équipe est premium avant de faire l'appel API
    if (!isPremiumTeam) {
      // Gérer le cas où l'équipe n'est pas premium
      // Vous pouvez retourner un objet ou lever une exception selon votre gestion d'erreur
      return {
        error: "Cette fonctionnalité est réservée aux équipes premium.",
        isPremiumFeature: false,
      };
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/sentiment/analyse-sentiment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({
            content: post.content,
          }),
        }
      );

      if (!response.ok) {
        // Gérer les réponses d'erreur de l'API
        throw new Error("Problème lors de la récupération des données");
      }

      const data = await response.json();
      // Retournez la réponse de l'API si l'équipe est premium
      return { ...data, isPremiumFeature: true };
    } catch (error) {
      console.error("Error when calling API :", error);
      return {
        error: error.message,
        isPremiumFeature: true,
      };
    }
  }

  function getExcludedUrls(keywords) {
    if (!Array.isArray(keywords)) {
      return [];
    }

    return keywords.reduce((acc, keyword) => {
      if (keyword.urls && Array.isArray(keyword.urls)) {
        // Nettoyer les URLs pour enlever les variations communes
        const cleanedUrls = keyword.urls.map((url) =>
          url
            .replace(/^https?:\/\//, "")
            .replace(/www\./, "")
            .replace(/\/$/, "")
        );
        acc.push(...cleanedUrls);
      }
      return acc;
    }, []);
  }

  // Initialisation d'excludedUrls à un tableau vide pour éviter des références undefined
  let excludedUrls = [];

  // Vérifiez que les propriétés nécessaires existent avant de procéder
  if (context && context.teamDetails && context.teamDetails.keyword) {
    excludedUrls = getExcludedUrls(context.teamDetails.keyword);
  }

  const shouldShowPost = (post) => {
    const isFilteredByReadStatus =
      mentionFilter === "all" || (mentionFilter === "unread" && !post.read);
    const isNotExcludedUrl = post.author
      ? !excludedUrls.some((exUrl) => post.author.includes(exUrl))
      : true;

    // console.log("Excluded URLs", excludedUrls);
    // console.log("Post Author URL", post.author);
    // console.log("Is Not Excluded URL", isNotExcludedUrl);

    return isFilteredByReadStatus && isNotExcludedUrl;
  };

  function renderSentiment(score) {
    const sentimentContainerClass =
      score > 0 ? "goodNote" : score < 0 ? "badNote" : "neutNote";
    const sentimentIcon =
      score > 0
        ? "good fa-face-smile"
        : score < 0
        ? "bad fa-face-sad-tear"
        : "neutrale fa-face-meh";
    const sentimentTextClass =
      score > 0 ? "positiveNote" : score < 0 ? "negativeNote" : "neutraleNote";
    const sentimentText =
      score > 0 ? t("good") : score < 0 ? t("bad") : t("neutrale");

    return {
      sentimentContainerClass,
      sentimentIcon,
      sentimentTextClass,
      sentimentText,
    };
  }

  useEffect(() => {
    if (selectedPost) {
      getSentimentAnalysis(selectedPost).then((data) => {
        setSentimentData(data);
      });
    }
  }, [selectedPost]);

  function getIconClass(sourceKey) {
    if (sourceKey === "news") {
      return (
        <img
          src="/background/newsGoogle.png"
          className="fa-3x"
          alt="Google News"
        />
      );
    } else if (sourceKey === "indiehacker") {
      return (
        <img
          src="/social/indiehacker.png"
          className="googleFeed"
          alt="Indie Hacker"
        />
      );
    } else {
      return `${sourceKey} fa-brands fa-${sourceKey}`;
    }
  }

  const loadMorePosts = () => {
    setDisplayedPostsCount((prevCount) => prevCount + 20);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      loadMorePosts();
    }
  };

  const textLinkedin =
    t("discover_how_buska_can_help_you") +
    keyword +
    t("indentify_the_right_person");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setLoading(true);

    if (dataFromSearch && Array.isArray(dataFromSearch.searches)) {
      const postsMap = new Map();

      dataFromSearch.searches.forEach((search) => {
        if (Array.isArray(selectedSources)) {
          selectedSources.forEach((source) => {
            // Vérifie que la source existe dans les résultats
            if (
              search.results &&
              search.results[source] &&
              Array.isArray(search.results[source].data?.posts)
            ) {
              search.results[source].data.posts.forEach((post) => {
                const uniqueKey = `${post.id}-${source}`;
                if (!postsMap.has(uniqueKey)) {
                  postsMap.set(uniqueKey, {
                    ...post,
                    sourceKey: source,
                    searchDate: search.createdAt,
                  });
                }
              });
            } else {
              console.warn(
                `Source "${source}" introuvable dans les résultats pour la recherche.`
              );
            }
          });
        }
      });

      const uniquePosts = Array.from(postsMap.values());

      uniquePosts.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        const isValidDateA = dateA instanceof Date && !isNaN(dateA);
        const isValidDateB = dateB instanceof Date && !isNaN(dateB);

        if (isValidDateA && isValidDateB) {
          return dateB - dateA;
        }
        if (!isValidDateA) {
          return 1;
        }
        if (!isValidDateB) {
          return -1;
        }
      });

      setAllPosts(uniquePosts);
    }

    setLoading(false);
  }, [dataFromSearch, selectedSources]);

  function getCurrentHourPercentage() {
    // Obtenez la date et l'heure actuelles
    const now = new Date();

    // Récupérez l'heure actuelle
    const currentHour = now.getHours();

    // Récupérez les minutes actuelles
    const currentMinutes = now.getMinutes();

    // Calculez le pourcentage basé sur les minutes
    const percentage = (currentMinutes / 60) * 100;

    return percentage;
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const teamId = context.teamId;
      const keyword = context.keyword;

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      };

      const url = `${
        process.env.REACT_APP_BASEURL
      }/api/search/getSearchesByTeamId?teamId=${teamId}&keyword=${encodeURIComponent(
        keyword
      )}`;
      const response = await fetch(url, { headers });
      let result = await response.json();

      // Filtrer les recherches pour ne garder que celles qui correspondent au mot-clé sélectionné
      result.searches = result.searches.filter(
        (search) => search.keyword === context.keyword
      );

      // Trier les recherches par date de manière décroissante
      result.searches.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );

      setDataFromSearch(result);
      // setSelectedSources(Object.keys(result?.searches?.[0]?.results || {}));

      // Juste pour compter le nombre de résutltats
      let totalPosts = 0;

      if (result.searches) {
        const filteredSearches = result.searches.filter(
          (search) => search.keyword === context.keyword
        );

        totalPosts = filteredSearches.reduce((acc, search) => {
          // Pour chaque 'search', obtenir le total de posts de toutes ses sources
          const searchTotalPosts = Object.values(search.results || {}).reduce(
            (innerAcc, source) => {
              return (
                innerAcc +
                (Array.isArray(source.data?.posts)
                  ? source.data.posts.length
                  : 0)
              );
            },
            0
          );
          return acc + searchTotalPosts;
        }, 0);
      }

      setNombreResults(totalPosts);
      setLoading(false);

      // Obtenir des informations sur l'équipe
      const teamUrl = `${process.env.REACT_APP_BASEURL}/api/teams/team-info/${context.teamId}`;
      const teamResponse = await fetch(teamUrl);
      const teamData = await teamResponse.json();

      const trialOver = context.isFreeTrialOver;

      // Vérifiez si l'équipe est premium
      if (teamData.premium || !trialOver) {
        setIsPremiumTeam(true);
      } else {
        setIsPremiumTeam(false);
      }
    };

    fetchData();
  }, [
    context.teamId,
    context.keyword,
    context.isFreeTrialOver,
    selectedSources,
  ]);

  useEffect(() => {
    if (mentionFilter === "unread") {
      // Sauvegardez le nombre actuel de posts affichés
      setPreviousDisplayedPostsCount(displayedPostsCount);
      // Affichez toutes les mentions si le filtre est sur "non lues"
      setDisplayedPostsCount(allPosts.length);
    } else {
      // Rétablissez le nombre de mentions affichées à la valeur précédente lorsque le filtre est enlevé
      setDisplayedPostsCount(previousDisplayedPostsCount);
    }
  }, [mentionFilter]);

  useEffect(() => {
    if (allPosts.length > 0) {
      const detectedLanguages = [
        ...new Set(allPosts.map((post) => post.language)),
      ];
      // Sélectionner toutes les langues par défaut si `selectedLanguages` est vide
      if (selectedLanguages.length === 0) {
        setSelectedLanguages(detectedLanguages);
      }
    }
  }, [allPosts]);

  useEffect(() => {
    if (dataFromSearch && selectedSources.length > 0) {
      let filteredPosts = allPosts.filter((post) => {
        const matchesSource = selectedSources.includes(post.sourceKey);
        const matchesLanguage = selectedLanguages.includes(post.language);
        const matchesReadStatus =
          mentionFilter === "all" || (mentionFilter === "unread" && !post.read);

        return matchesSource && matchesLanguage && matchesReadStatus;
      });

      setFilteredResults(filteredPosts);
      mixpanel.track("Load_page", {
        user: context.userInfos.email,
        page: "Feed",
      });
    }
  }, [
    mentionFilter,
    allPosts,
    selectedSources,
    selectedLanguages,
    dataFromSearch,
  ]);

  const FausseVignetteLinkedIn = () => {
    return (
      <div
        className="alignVign triangle blurredFake"
        onClick={() => {
          setShowPlansModal(true);
          mixpanel.track("Fake Linkedin Mention");
        }}
      >
        <div className="mentiondata nonread ">
          <div className="mentionContent ">
            <div className="lockOverlay">
              <i className="fa-solid fa-lock lockIcon"></i>
            </div>
            <a className="postTextContent">
              <div className="webkitBox">
                <i className="linkedin fa-brands fa-linkedin fa-3x"></i>
                <div className="ml10 lineHeight">
                  <span className="NameSocial">LinkedIn</span>
                  <span className="dateAgo">
                    {moment().locale("fr").format("LL")}
                  </span>
                </div>
              </div>
              <p
                style={{ color: "black", marginTop: "10px" }}
                className="text-truncateFeed"
              >
                <img src="https://flagcdn.com/16x12/us.png" alt="flag us" />{" "}
                Tristan Berguer -
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(textLinkedin, keyword),
                  }}
                ></span>
              </p>
            </a>
            <div className="analysisSentimentVignette goodNote">
              <h4>{t("Sentiment")}</h4>
              <div>
                <i className="fa-solid good fa-face-smile"></i>
                <span className="positiveNote">{t("good")}</span>
              </div>
            </div>
            <div className="bottomMention linkHeight">
              <a target="_blank" className="buttonBottom">
                <i className="fa-solid fa-up-right-from-square"></i>{" "}
                {t("Reply")}
              </a>
              <a target="_blank" className="buttonBottom">
                <i className="fa-solid fa-user"></i> {t("Lead_Profile")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="">
            <>
              {/* <NavDashboard /> */}
              <main id="UserDashboard">
                <div className="">
                  {/* <h1>Feed</h1> */}
                  <MenuFeed
                    allPosts={allPosts}
                    selectedSources={selectedSources}
                    setSelectedSources={setSelectedSources}
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                    isClockView={isClockView}
                    setIsClockView={setIsClockView}
                    onMentionFilterChange={setMentionFilter}
                  />
                </div>
                {isClockView ? (
                  <>
                    <div className="whiteVignFeed">
                      <div className="mentionsContainer">
                        {allPosts.length > 0 &&
                          (() => {
                            let postCount = 0;
                            let prevDate = null;
                            const sortedPosts = [...allPosts].sort(
                              (a, b) => new Date(b.date) - new Date(a.date)
                            );

                            const groupedByDate = sortedPosts.reduce(
                              (acc, post) => {
                                const dateKey = moment(post.date).format("LL");
                                if (!acc[dateKey]) {
                                  acc[dateKey] = [];
                                }
                                acc[dateKey].push(post);
                                return acc;
                              },
                              {}
                            );

                            return Object.entries(groupedByDate).flatMap(
                              ([date, postsForDate]) => {
                                const chunkedPosts = [];
                                for (
                                  let i = 0;
                                  i < postsForDate.length;
                                  i += 4
                                ) {
                                  chunkedPosts.push(
                                    postsForDate.slice(i, i + 4)
                                  );
                                }
                                // Filtrer les posts pour ce jour spécifique
                                const filteredPosts =
                                  postsForDate.filter(shouldShowPost);
                                if (filteredPosts.length === 0) {
                                  return []; // Ne pas afficher la date si aucun post à afficher
                                }

                                return chunkedPosts.map((chunk, chunkIndex) => (
                                  <>
                                    {date !== prevDate && (
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          fontWeight: "bold",
                                          marginLeft: "25px",
                                          background: "#F2F2F2",
                                          borderRadius: "15px",
                                          padding: "10px 22px",
                                          width: "96%",
                                        }}
                                      >
                                        {(prevDate = date)}
                                      </div>
                                    )}
                                    <div
                                      key={
                                        `${date}-${chunkIndex}` + Math.random()
                                      }
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {chunk
                                        .filter(shouldShowPost)
                                        .map((post) => {
                                          postCount++;
                                          return (
                                            <div
                                              className={`alignVign ${
                                                isPremiumTeam
                                                  ? ""
                                                  : postCount > 8
                                                  ? "blurredFeed"
                                                  : ""
                                              }`}
                                              key={post.id}
                                              style={{ width: "25%" }}
                                            >
                                              {loading ? (
                                                <Loader />
                                              ) : (
                                                <div
                                                  className={`mentiondata ${
                                                    post.id === selectedPostId
                                                      ? "highlighted"
                                                      : ""
                                                  } ${
                                                    !post.read ? "nonread" : ""
                                                  }`}
                                                >
                                                  <div className="mentionContent">
                                                    <a
                                                      onClick={() =>
                                                        handlePostClick(
                                                          post,
                                                          chunkIndex
                                                        )
                                                      }
                                                    >
                                                      <div className="webkitBox">
                                                        {post.favicon ? (
                                                          <img
                                                            className="favicon"
                                                            src={post.favicon}
                                                            alt={post.sourceKey}
                                                          />
                                                        ) : post.sourceKey ===
                                                          "news" ? (
                                                          <img
                                                            src="/background/newsGoogle.png"
                                                            className="google"
                                                            alt="Google News"
                                                          />
                                                        ) : post.sourceKey ===
                                                          "indiehacker" ? (
                                                          <img
                                                            src="/social/indiehacker.png"
                                                            className="google"
                                                            alt="Indie Hacker"
                                                          />
                                                        ) : post.sourceKey ===
                                                          "twitter" ? (
                                                          <img
                                                            src="/background/logo-x-twitter.svg"
                                                            className="twitter"
                                                            alt="Twitter"
                                                          />
                                                        ) : (
                                                          <i
                                                            className={
                                                              getIconClass(
                                                                post.sourceKey
                                                              ) + " fa-3x"
                                                            }
                                                          ></i>
                                                        )}
                                                        <div className="ml10 lineHeight">
                                                          <span className="NameSocial">
                                                            {post.name
                                                              ? post.name
                                                              : post.sourceKey
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                                post.sourceKey.slice(
                                                                  1
                                                                )}
                                                          </span>
                                                          <span className="dateAgo">
                                                            {moment(
                                                              post.date
                                                            ).isValid()
                                                              ? moment(
                                                                  post.date
                                                                )
                                                                  .locale("fr")
                                                                  .format("LL")
                                                              : post.date}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <p
                                                        style={{
                                                          color: "black",
                                                          marginTop: "10px",
                                                        }}
                                                        className="text-truncateFeed"
                                                      >
                                                        {post.language && (
                                                          <img
                                                            src={`https://flagcdn.com/16x12/${
                                                              post.language ===
                                                              "en"
                                                                ? "us"
                                                                : post.language
                                                            }.png`}
                                                            onError={(e) =>
                                                              (e.target.style.display =
                                                                "none")
                                                            } // Masque l'image en cas d'erreur de chargement
                                                            alt={`flag ${
                                                              post.language ===
                                                              "en"
                                                                ? "us"
                                                                : post.language
                                                            }`}
                                                          />
                                                        )}{" "}
                                                        {post.name
                                                          ? post.name + " - "
                                                          : ""}{" "}
                                                        {post.content}
                                                      </p>
                                                    </a>
                                                    {post.sentiment && (
                                                      <div
                                                        className={`analysisSentimentVignette ${
                                                          isPremiumTeam
                                                            ? post.sentiment
                                                              ? post.sentiment ===
                                                                "good"
                                                                ? "goodNote"
                                                                : post.sentiment ===
                                                                  "bad"
                                                                ? "badNote"
                                                                : "neutNote"
                                                              : ""
                                                            : "lockNote"
                                                        }`}
                                                      >
                                                        <h4>
                                                          {t("Sentiment")}
                                                        </h4>
                                                        {isPremiumTeam ? (
                                                          post.sentiment ? (
                                                            <div>
                                                              <i
                                                                className={`fa-solid ${
                                                                  post.sentiment ===
                                                                  "good"
                                                                    ? "good fa-face-smile"
                                                                    : post.sentiment ===
                                                                      "bad"
                                                                    ? "bad fa-face-sad-tear"
                                                                    : "neutrale fa-face-meh"
                                                                }`}
                                                              ></i>
                                                              <span
                                                                className={
                                                                  post.sentiment ===
                                                                  "good"
                                                                    ? "positiveNote"
                                                                    : post.sentiment ===
                                                                      "bad"
                                                                    ? "negativeNote"
                                                                    : "neutraleNote"
                                                                }
                                                              >
                                                                {t(
                                                                  post.sentiment
                                                                )}
                                                              </span>
                                                            </div>
                                                          ) : null
                                                        ) : (
                                                          <div className="">
                                                            <i className="lock fa-solid fa-lock"></i>
                                                            <span
                                                              className="lockedNote"
                                                              onClick={() =>
                                                                setShowPlansModal(
                                                                  true
                                                                )
                                                              }
                                                            >
                                                              {t(
                                                                "Unlock_feature"
                                                              )}
                                                            </span>
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}

                                                    <div className="bottomMention linkHeight">
                                                      <a
                                                        target="_blank"
                                                        href={post.link}
                                                      >
                                                        <i className="fa-solid fa-up-right-from-square"></i>{" "}
                                                        {t("Reply")}
                                                      </a>
                                                      <span
                                                        onClick={() =>
                                                          showModal(post)
                                                        }
                                                      >
                                                        <i className="fa-solid fa-share-nodes"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                      {Array(
                                        4 - chunk.filter(shouldShowPost).length
                                      )
                                        .fill()
                                        .map(() => (
                                          <div
                                            className="noMentions"
                                            style={{ width: "25%" }}
                                          >
                                            <div className="empty">
                                              <div className="avatarAndTraits">
                                                <img
                                                  className="avatar"
                                                  src="/empty/Avatar.svg"
                                                />
                                                <div className="traitLong"></div>
                                              </div>
                                              <div className="traitShort"></div>
                                              <div className="traitMoyen"></div>
                                              <div className="traitMoyen"></div>
                                              <div className="traitMoyenLong"></div>
                                              <div className="traitShort"></div>
                                              <div className="traitLong"></div>
                                              <div className="traitShort"></div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </>
                                ));
                              }
                            );
                          })()}
                      </div>
                      <Modal
                        className={`feedModal ${bgColorClass}`}
                        open={isModalVisible}
                        onCancel={handleCancel}
                        footer={null}
                        centered
                        width="35%"
                        closable={false}
                      >
                        <div className={`modal-contentWrapper`}>
                          {" "}
                          {/* Ajouté pour le positionnement flex */}
                          <div className="post-contentModal">
                            <div className="post-header">
                              {/* Icône à gauche */}
                              <div className="icon-container">
                                {selectedPost &&
                                  renderIconForSource(
                                    selectedPost.sourceKey,
                                    selectedPost.favicon
                                  )}
                              </div>

                              {/* Nom du réseau et date à droite */}
                              <div className="details-container">
                                <span className="post-name">
                                  {selectedPost
                                    ? selectedPost.name
                                    : t("No_post_selected")}
                                </span>
                                <span className="dateAgo">
                                  {selectedPost &&
                                  selectedPost.date &&
                                  moment(selectedPost.date).isValid()
                                    ? moment(selectedPost.date)
                                        .locale("fr")
                                        .format("LL")
                                    : selectedPost
                                    ? selectedPost.date
                                    : t("No_date_available")}
                                </span>
                              </div>
                            </div>

                            {/* Contenu du post en dessous */}
                            <div className="post-body">
                              {selectedPost
                                ? selectedPost.content
                                  ? selectedPost.content
                                  : selectedPost.name
                                : t("No_content_available")}
                            </div>
                          </div>
                          <div className="poweredShare">
                            <img
                              style={{ display: "block", margin: "20px auto" }}
                              src="/logo.svg"
                            />
                            <p>{t("Your_brand_watchtower")}</p>
                          </div>
                        </div>
                        <div className="custom-footer">
                          <div className="color-selector">
                            <div
                              className="color-option exportGradiant"
                              onClick={() => setBgColorClass("exportGradiant")}
                            ></div>
                            <div
                              className="color-option exportGrey"
                              onClick={() => setBgColorClass("exportGrey")}
                            ></div>
                            <div
                              className="color-option exportPurple"
                              onClick={() => setBgColorClass("exportPurple")}
                            ></div>
                          </div>
                          <div className="action-buttons">
                            <button
                              className="export-button"
                              onClick={exportModalAsImage}
                            >
                              <i className="fa-solid fa-download"></i>{" "}
                              {t("Export")}
                            </button>
                          </div>
                        </div>
                      </Modal>
                      <Drawer
                        width={550}
                        className="feedDrawer"
                        style={{ top: "15px", bottom: "15px", right: "15px" }}
                        // mask={false}
                        title={
                          <>
                            <div className="webkitBoxFeed">
                              {selectedPost &&
                                renderIconForSource(
                                  selectedPost.sourceKey,
                                  selectedPost.favicon
                                )}
                              {selectedPost ? (
                                <>
                                  <div className="ml10 lineHeight">
                                    <span className="NameSocial">
                                      {selectedPost.name
                                        ? selectedPost.name
                                        : selectedPost.sourceKey}
                                    </span>
                                    <span className="dateAgo">
                                      {selectedPost.date}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                t("Post_Details")
                              )}

                              <div className="topRightDrawer">
                                <div className="imgRank">
                                  <i
                                    onClick={() => GoodReview(selectedPost)}
                                    className={`petitRond fa-regular fa-thumbs-up ${
                                      selectedPost &&
                                      selectedPost.evaluate === "good"
                                        ? "evaluate"
                                        : ""
                                    }`}
                                  ></i>
                                  <i
                                    onClick={() => BadReview(selectedPost)}
                                    className={`petitRond fa-regular fa-thumbs-down ${
                                      selectedPost &&
                                      selectedPost.evaluate === "bad"
                                        ? "evaluate"
                                        : ""
                                    }`}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                        placement="right"
                        onClose={onClose}
                        open={open}
                        centered
                        bodyStyle={{ backgroundColor: "white" }} // Définir le fond du corps de la modal à blanc
                      >
                        <div className="contentWrapper">
                          {selectedPost
                            ? getEmbeddedContent(selectedPost)
                            : t("No_post_selected")}
                          <br />
                          {selectedPost && selectedPost.link ? (
                            <a
                              href={selectedPost.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            ></a>
                          ) : null}
                          {/* <p
                            className="preserve-newlines"
                            dangerouslySetInnerHTML={{
                              __html: highlightKeyword(
                                selectedPost
                                  ? selectedPost.content
                                  : t("No_post_selected"),
                                keyword
                              ),
                            }}
                          ></p> */}

                          <div
                            className={`analysisSentiment ${
                              sentimentData
                                ? isPremiumTeam
                                  ? renderSentiment(sentimentData.score)
                                      .sentimentContainerClass
                                  : "lockNote"
                                : ""
                            }`}
                          >
                            <h4>{t("Sentiment")}</h4>
                            {sentimentData ? (
                              isPremiumTeam ? (
                                <div>
                                  <i
                                    className={`fa-solid ${
                                      renderSentiment(sentimentData.score)
                                        .sentimentIcon
                                    }`}
                                  ></i>
                                  <span
                                    className={
                                      renderSentiment(sentimentData.score)
                                        .sentimentTextClass
                                    }
                                  >
                                    {
                                      renderSentiment(sentimentData.score)
                                        .sentimentText
                                    }
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <i className="lock fa-solid fa-lock"></i>
                                  <span
                                    className="lockedNote"
                                    onClick={() => setShowPlansModal(true)}
                                  >
                                    {t("Unlock_feature")}
                                  </span>
                                </div>
                              )
                            ) : null}
                          </div>

                          <br />
                          {selectedPost && selectedPost.link ? (
                            <a
                              href={selectedPost.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="ctaResumeMention">
                                <i className="fa-solid fa-up-right-from-square"></i>{" "}
                                {t("Reply")}
                              </button>
                            </a>
                          ) : null}

                          {/* <button className="ctaResumeMention">
                        <i className="fa-solid fa-wand-magic-sparkles"></i>{" "}
                        Resume this mention
                      </button> */}

                          <button
                            className="ctaResumeMention"
                            onClick={() => showModal(selectedPost)}
                          >
                            <i className="fa-solid fa-share-nodes"></i>{" "}
                            {t("Share_this_mention")}
                          </button>
                        </div>
                        <div className="bottomDrawer">
                          <img src="/delivery.svg" />
                        </div>
                      </Drawer>
                    </div>
                  </>
                ) : (
                  <div className="whiteVignFeed">
                    <div className="mentionsContainer">
                      {/* Fake mention linkedin  */}
                      {/* {currentPlan !== "Ultimate" && FausseVignetteLinkedIn()} */}

                      {allPosts.length > 0 &&
                        (() => {
                          // Ajustez le nombre de résultats à afficher en fonction de isPremiumTeam
                          const postsToDisplay = allPosts
                            .filter((post) =>
                              selectedLanguages.includes(post.language)
                            ) // Filtrer par langue
                            .filter(shouldShowPost) // Appliquer d'autres filtres que vous avez
                            .slice(0, isPremiumTeam ? displayedPostsCount : 11); // Limiter le nombre de posts affichés

                          return postsToDisplay
                            .filter(shouldShowPost)
                            .map((post, index) => {
                              // N'appliquez pas la classe blurredFeed si isPremiumTeam est à true
                              const isBlurred = isPremiumTeam
                                ? ""
                                : index >= 8
                                ? "blurredFeed"
                                : "";

                              return (
                                <>
                                  {/* {showAd && selectedAd && (
                                    <div className="alignVign">
                                      <div className="mentiondataAds">
                                        <div
                                          className="mentiondataAds"
                                          onClick={() => {
                                            setShowPlansModal(true);
                                            mixpanel.track("Upsell Card");
                                          }}
                                        >
                                          <span className="didyouknow">
                                            {selectedAd.title}
                                          </span>
                                          <span className="text-truncateFeed">
                                            {selectedAd.text}
                                          </span>
                                          <div className="imgAndButtonContainer">
                                            <img
                                              src={selectedAd.image}
                                              className="imgMedal"
                                              alt="Ad Image"
                                            />
                                            <button className="buttonAdsBuska">
                                              <span className="textButtonAds">
                                                {selectedAd.buttonText}
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )} */}

                                  <div
                                    className={`alignVign ${isBlurred} ${
                                      index === selectedVignetteIndex ? "" : ""
                                    } ${
                                      !post.read &&
                                      index !== selectedVignetteIndex
                                        ? "triangle"
                                        : ""
                                    }`}
                                    key={post.id}
                                  >
                                    {loading ? (
                                      <Loader />
                                    ) : (
                                      <div
                                        className={`mentiondata ${
                                          index === selectedVignetteIndex
                                            ? "highlighted"
                                            : ""
                                        } ${!post.read ? "nonread" : ""}`}
                                      >
                                        <div className="mentionContent">
                                          <a
                                            onClick={() =>
                                              handlePostClick(post, index)
                                            }
                                            className="postTextContent"
                                          >
                                            <div className="webkitBox">
                                              {post.favicon ? (
                                                <img
                                                  className="favicon"
                                                  src={post.favicon}
                                                  alt={post.sourceKey}
                                                />
                                              ) : post.sourceKey === "news" ? (
                                                <img
                                                  src="/background/newsGoogle.png"
                                                  className="google"
                                                  alt="Google News"
                                                />
                                              ) : post.sourceKey ===
                                                "indiehacker" ? (
                                                <img
                                                  src="/social/indiehacker.png"
                                                  className="google"
                                                  alt="Indie Hacker"
                                                />
                                              ) : post.sourceKey ===
                                                "twitter" ? (
                                                <img
                                                  src="/background/logo-x-twitter.svg"
                                                  className="twitter"
                                                  alt="Google News"
                                                />
                                              ) : (
                                                <i
                                                  className={
                                                    getIconClass(
                                                      post.sourceKey
                                                    ) + " fa-3x"
                                                  }
                                                ></i>
                                              )}
                                              <div className="ml10 lineHeight">
                                                <span className="NameSocial">
                                                  {post.name
                                                    ? post.name
                                                    : post.sourceKey
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      post.sourceKey.slice(1)}
                                                </span>
                                                <span className="dateAgo">
                                                  {moment(post.date).isValid()
                                                    ? moment(post.date)
                                                        .locale("fr")
                                                        .format("LL")
                                                    : post.date}
                                                </span>
                                              </div>
                                            </div>
                                            <p
                                              style={{
                                                color: "black",
                                                marginTop: "10px",
                                              }}
                                              className="text-truncateFeed"
                                            >
                                              {post.language && (
                                                <img
                                                  src={`https://flagcdn.com/16x12/${
                                                    post.language === "en"
                                                      ? "us"
                                                      : post.language
                                                  }.png`}
                                                  onError={(e) =>
                                                    (e.target.style.display =
                                                      "none")
                                                  } // Masque l'image en cas d'erreur de chargement
                                                  alt={`flag ${
                                                    post.language === "en"
                                                      ? "us"
                                                      : post.language
                                                  }`}
                                                />
                                              )}{" "}
                                              {post.name
                                                ? post.name + " - "
                                                : ""}{" "}
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: highlightKeyword(
                                                    post.content,
                                                    keyword
                                                  ),
                                                }}
                                              ></span>
                                            </p>
                                          </a>

                                          {post.sentiment && (
                                            <div
                                              className={`analysisSentimentVignette ${
                                                isPremiumTeam
                                                  ? post.sentiment
                                                    ? post.sentiment === "good"
                                                      ? "goodNote"
                                                      : post.sentiment === "bad"
                                                      ? "badNote"
                                                      : "neutNote"
                                                    : ""
                                                  : "lockNote"
                                              }`}
                                            >
                                              <h4>{t("Sentiment")}</h4>
                                              {isPremiumTeam ? (
                                                post.sentiment ? (
                                                  <div>
                                                    <i
                                                      className={`fa-solid ${
                                                        post.sentiment ===
                                                        "good"
                                                          ? "good fa-face-smile"
                                                          : post.sentiment ===
                                                            "bad"
                                                          ? "bad fa-face-sad-tear"
                                                          : "neutrale fa-face-meh"
                                                      }`}
                                                    ></i>
                                                    <span
                                                      className={
                                                        post.sentiment ===
                                                        "good"
                                                          ? "positiveNote"
                                                          : post.sentiment ===
                                                            "bad"
                                                          ? "negativeNote"
                                                          : "neutraleNote"
                                                      }
                                                    >
                                                      {t(post.sentiment)}
                                                    </span>
                                                  </div>
                                                ) : null
                                              ) : (
                                                <div className="">
                                                  <i className="lock fa-solid fa-lock"></i>
                                                  <span
                                                    className="lockedNote"
                                                    onClick={() =>
                                                      setShowPlansModal(true)
                                                    }
                                                  >
                                                    {t("Unlock_feature")}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          )}

                                          <div
                                            className={`bottomMention linkHeight ${
                                              !post.author
                                                ? "bottomMentionCentered"
                                                : ""
                                            }`}
                                          >
                                            <a
                                              target="_blank"
                                              className="buttonBottom"
                                              href={`${post.link}?=fromBuska.io`}
                                            >
                                              <i className="fa-solid fa-up-right-from-square"></i>{" "}
                                              {t("Reply")}
                                            </a>
                                            {post.author && (
                                              <a
                                                target="_blank"
                                                className="buttonBottom"
                                                href={`${post.author}?=fromBuska.io`}
                                              >
                                                <i className="fa-solid fa-user"></i>{" "}
                                                {t("Lead_Profile")}
                                              </a>
                                            )}

                                            {/* <span
                                              onClick={() => showModal(post)}
                                            >
                                              <i className="fa-solid fa-share-nodes"></i>
                                            </span> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            });
                        })()}
                      {nombreResults <= 4 && (
                        <>
                          <div className="mentions-container">
                            {Array(Math.max(0, 8 - nombreResults))
                              .fill()
                              .map((_, idx) => (
                                <div key={idx} className="noMentions">
                                  <div className="empty">
                                    <div className="avatarAndTraits">
                                      <img
                                        className="avatar"
                                        src="/empty/Avatar.svg"
                                      />
                                      <div className="traitLong"></div>
                                    </div>
                                    <div className="traitShort"></div>
                                    <div className="traitMoyen"></div>
                                    <div className="traitMoyen"></div>
                                    <div className="traitMoyenLong"></div>
                                    <div className="traitShort"></div>
                                    <div className="traitLong"></div>
                                    <div className="traitShort"></div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </div>

                    {currentPlan !== "Pro" &&
                      currentPlan !== "Ultimate" &&
                      nombreResults > 4 && (
                        <div className="blurred-overlay">
                          <div className="centered-content">
                            <div className="text-content">
                              <img src="/logo.svg" />
                              <br />
                              {t(
                                "unlock_the_full_potential_of_buska_to_see_all"
                              )}
                              <br />
                              <span
                                className="active-linkMenu underline"
                                onClick={() => setShowPlansModal(true)}
                              >
                                {t("choose_a_plan")}
                              </span>
                            </div>
                            <img
                              src="hello.svg"
                              className="imgPlanBuska"
                              alt="Image Description"
                            />
                          </div>
                        </div>
                      )}

                    {currentPlan !== "Ultimate" && selectedAd && showAd && (
                      <div className="adContainer">
                        <button
                          className="closeAd"
                          onClick={() => setShowAd(false)}
                        >
                          ×
                        </button>
                        <div
                          className="mentiondataAds"
                          onClick={() => {
                            setShowPlansModal(true);
                            mixpanel.track("Upsell Card");
                          }}
                        >
                          <span className="didyouknow">{selectedAd.title}</span>
                          <span className="text-truncateFeed">
                            {selectedAd.text}
                          </span>
                          <div className="imgAndButtonContainer">
                            <img
                              src={selectedAd.image}
                              className="imgMedal"
                              alt="Ad Image"
                            />
                            <button className="buttonAdsBuska">
                              <span className="textButtonAds">
                                {selectedAd.buttonText}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {nombreResults == 0 && (
                      <div className="overlay-white">
                        <div className="centered-box">
                          <div className="inner-content">
                            <img src="/logo.svg" alt="Logo" />
                            <p>
                              {t("buska_scans_the_web_to_find_traces_keyword")}
                            </p>
                            <span className="comeBack">
                              {t("come_back_later_for_more_mentions")}
                            </span>
                            <br />
                          </div>
                          <img src="Searching.svg" alt="Image Description" />
                        </div>
                      </div>
                    )}

                    <Plans
                      isVisible={showPlansModal}
                      onClose={() => setShowPlansModal(false)}
                    />

                    {/* {(!isPremiumTeam || nombreResults <= 4) && (
                      <div className="blurred-overlay">
                        <div className="centered-content">
                          <div className="text-content">
                            <img src="/logo.svg" />
                            <br />
                            buska keeps an eye out to get new mentions
                            <br />
                            of your brand online...
                          </div>
                          <img src="Searching.svg" alt="Searching" />
                        </div>
                      </div>
                    )} */}

                    <Modal
                      className={`feedModal ${bgColorClass}`}
                      open={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                      centered
                      width="35%"
                      closable={false}
                    >
                      <div className={`modal-contentWrapper`}>
                        {" "}
                        {/* Ajouté pour le positionnement flex */}
                        <div className="post-contentModal">
                          <div className="post-header">
                            {/* Icône à gauche */}
                            <div className="icon-container">
                              {selectedPost &&
                                renderIconForSource(
                                  selectedPost.sourceKey,
                                  selectedPost.favicon
                                )}
                            </div>

                            {/* Nom du réseau et date à droite */}
                            <div className="details-container">
                              <span className="post-name">
                                {selectedPost
                                  ? selectedPost.name
                                  : t("No_post_selected")}
                              </span>
                              <span className="dateAgo">
                                {selectedPost &&
                                selectedPost.date &&
                                moment(selectedPost.date).isValid()
                                  ? moment(selectedPost.date)
                                      .locale("fr")
                                      .format("LL")
                                  : selectedPost
                                  ? selectedPost.date
                                  : t("No_date_available")}
                              </span>
                            </div>
                          </div>

                          {/* Contenu du post en dessous */}
                          <div className="post-body">
                            {selectedPost
                              ? selectedPost.content
                                ? selectedPost.content
                                : selectedPost.name
                              : t("No_content_available")}
                          </div>
                        </div>
                        <div className="poweredShare">
                          <img
                            style={{ display: "block", margin: "20px auto" }}
                            src="/logo.svg"
                          />
                          <p>{t("Your_brand_watchtower")}</p>
                        </div>
                      </div>
                      <div className="custom-footer">
                        <div className="color-selector">
                          <div
                            className="color-option exportGradiant"
                            onClick={() => setBgColorClass("exportGradiant")}
                          ></div>
                          <div
                            className="color-option exportGrey"
                            onClick={() => setBgColorClass("exportGrey")}
                          ></div>
                          <div
                            className="color-option exportPurple"
                            onClick={() => setBgColorClass("exportPurple")}
                          ></div>
                        </div>
                        <div className="action-buttons">
                          <button
                            className="export-button"
                            onClick={exportModalAsImage}
                          >
                            <i className="fa-solid fa-download"></i>{" "}
                            {t("Export")}
                          </button>
                        </div>
                      </div>
                    </Modal>

                    {!isPremiumTeam && (
                      <Modal
                        title={
                          <span className="modalTitleFreeTrialOver">
                            {t("Your_free_trial_is_finished")}
                          </span>
                        }
                        centered
                        open={isTrialOverModalVisible}
                        onCancel={() => setIsTrialOverModalVisible(false)}
                        footer={null}
                        className="custom-modal-free-trial-over"
                      >
                        <div className="modalCenterFreeTrial">
                          <p className="modalContentFreeTrialOver">
                            {t(
                              "you_can_still_continue_to_use_buska_and_track_your_mentions"
                            )}
                          </p>
                          <button
                            className="ctaPrimaryBlack"
                            onClick={() => setShowPlansModal(true)}
                          >
                            {t("choose_a_plan")}{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                          <img
                            className="imageFreeTrial"
                            src="/freeTrial_image.png"
                          />
                        </div>
                      </Modal>
                    )}

                    <Drawer
                      width={550}
                      className="feedDrawer"
                      // mask={false}
                      title={
                        <>
                          <div className="webkitBoxFeed">
                            {selectedPost &&
                              renderIconForSource(
                                selectedPost.sourceKey,
                                selectedPost.favicon
                              )}
                            {selectedPost ? (
                              <>
                                <div className="ml10 lineHeight">
                                  <span className="NameSocial">
                                    {selectedPost.name
                                      ? selectedPost.name
                                      : selectedPost.sourceKey}
                                  </span>
                                  <span className="dateAgo">
                                    {selectedPost &&
                                    selectedPost.date &&
                                    moment(selectedPost.date).isValid()
                                      ? moment(selectedPost.date)
                                          .locale("fr")
                                          .format("LL")
                                      : selectedPost
                                      ? selectedPost.date
                                      : t("No_date_available")}
                                  </span>
                                </div>
                              </>
                            ) : (
                              t("Post_Details")
                            )}

                            <div className="topRightDrawer">
                              {/* <div className="imgRank">
                                <i
                                  onClick={() => GoodReview(selectedPost)}
                                  className={`petitRond fa-regular fa-thumbs-up ${
                                    selectedPost &&
                                    selectedPost.evaluate === "good"
                                      ? "evaluate"
                                      : ""
                                  }`}
                                ></i>
                                <i
                                  onClick={() => BadReview(selectedPost)}
                                  className={`petitRond fa-regular fa-thumbs-down ${
                                    selectedPost &&
                                    selectedPost.evaluate === "bad"
                                      ? "evaluate"
                                      : ""
                                  }`}
                                ></i>
                              </div> */}
                            </div>
                          </div>
                        </>
                      }
                      placement="right"
                      onClose={onClose}
                      open={open}
                      centered
                      bodyStyle={{ backgroundColor: "white" }} // Définir le fond du corps de la modal à blanc
                    >
                      <div className="contentWrapper">
                        {selectedPost
                          ? getEmbeddedContent(selectedPost)
                          : t("No_post_selected")}
                        <br />
                        {selectedPost && selectedPost.link ? (
                          <a
                            href={selectedPost.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          ></a>
                        ) : null}
                        <br />
                        {/* <div
                          className={`analysisSentiment ${
                            sentimentData
                              ? isPremiumTeam
                                ? renderSentiment(sentimentData.score)
                                    .sentimentContainerClass
                                : "lockNote"
                              : ""
                          }`}
                        >
                          <h4>{t("Sentiment")}</h4>
                          {sentimentData ? (
                            isPremiumTeam ? (
                              <div>
                                <i
                                  className={`fa-solid ${
                                    renderSentiment(sentimentData.score)
                                      .sentimentIcon
                                  }`}
                                ></i>
                                <span
                                  className={
                                    renderSentiment(sentimentData.score)
                                      .sentimentTextClass
                                  }
                                >
                                  {
                                    renderSentiment(sentimentData.score)
                                      .sentimentText
                                  }
                                </span>
                              </div>
                            ) : (
                              <div>
                                <i className="lock fa-solid fa-lock"></i>
                                <span
                                  className="lockedNote"
                                  onClick={() => setShowPlansModal(true)}
                                >
                                  {t("Unlock_feature")}
                                </span>
                              </div>
                            )
                          ) : null}
                        </div> */}
                        <br />
                        {selectedPost && selectedPost.link ? (
                          <a
                            href={selectedPost.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button className="ctaResumeMention">
                              <i className="fa-solid fa-up-right-from-square"></i>{" "}
                              {t("Reply")}
                            </button>
                          </a>
                        ) : null}
                        {/* <button className="ctaResumeMention">
                        <i className="fa-solid fa-wand-magic-sparkles"></i>{" "}
                        Resume this mention
                      </button> */}
                        <button
                          className="ctaResumeMention"
                          onClick={() => showModal(selectedPost)}
                        >
                          <i className="fa-solid fa-share-nodes"></i>{" "}
                          {t("Share_this_mention")}
                        </button>
                      </div>
                      <div className="bottomDrawer">
                        <img src="/delivery.svg" />
                      </div>
                    </Drawer>
                  </div>
                )}
              </main>
            </>
          </div>
        </div>
        {nombreResults > 4 && <FooterLogin />}
      </>
    );
  }
}

export default Feed;
